<template>
  <div>
    <b-button
      size="sm"
      class="button-export-style ml-2 pl-2 pr-2 new-color-export-facture"
      title="Télecharger pdf facture "
      @click="handleExport"
    >
      <font-awesome-icon icon="file"
    /></b-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {},
  data() {
    return {};
  },
  methods: {
    handleExport() {
      this.$emit('exportFacturePdf');
    }
  },
  computed: {
    ...mapGetters([
      'chargementAppelPaiementThObligeeExportXlsFacture',
      'errorAppeliPaiementThObligeeExportXls'
    ])
  }
};
</script>

<style scoped lang="scss">
.new-color-export-facture {
  background-color: #0e0c5e;
}
</style>
